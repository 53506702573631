







































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import CBSR_DTO, {CBSRPeriodDTO} from "@/dto/request/cbsr/CBSR_DTO";
import CBSRService from "@/services/request/CBSRService";
import RouteNames from "@/router/RouteNames";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import {CBSRStatus} from "@/constants/request/RequestsStatuses";
import {v4 as uuidv4} from 'uuid';

const AppModule = namespace("App");

@Component({
  components: {SingleFileHolder}
})
export default class CBSR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private request: CBSR_DTO | null = null;

  mounted() {
    this.loadRequest();
  }

  loadRequest() {
    this.startLoading();
    CBSRService.getById(this.id).then(
        response => {
          this.request = response.data;
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          console.log(JSON.stringify(error));
        }
    );
  }

  get id() {
    return Number.parseInt(this.$route.params.id);
  }

  get getPeriods(): Array<CBSRPeriodDTO> {
    const periods = this.request?.accounts.flatMap(a => a.periods) || [];
    const uniquePeriodsSet = new Set(periods.map(p => this.uniqueKey(p)));
    return periods.filter(p => {
      const key = this.uniqueKey(p);
      if (uniquePeriodsSet.has(key)) {
        uniquePeriodsSet.delete(key);
        return true;
      }
      return false;
    });
  }

  uniqueKey(period: CBSRPeriodDTO): string {
    return `${period.period}_${period.year}`;
  }

  changePeriod(docId: number, period: CBSRPeriodDTO | null) {
    if (period == null) {
      return;
    }
    this.startLoading();
    CBSRService.updateDocumentPeriod(docId, period).then(
        ok => {
          this.stopLoading();
          this.loadRequest();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  get readyToComplete(): boolean {
    return this.request?.documents.filter(d => d.period == null).length == 0;
  }

  complete() {
    this.startLoading();
    CBSRService.complete(this.id).then(
        ok => {
          this.stopLoading();
          this.$router.push({
            name: RouteNames.PROFILE_COMPANY_BANK_STATEMENTS,
            params: {wsId: `${this.request?.request.company?.id}`, wsType: `${WorkspaceTypePathElement.COMPANY}`}
          });
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  get isCompleted(): boolean {
    return this.request?.request.status == CBSRStatus.COMPLETED;
  }


}
